* {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

a:focus,
button:focus,
input:focus,
input:active,
textarea:focus,
select:focus,
.form-control:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a:focus {
  color: #0cab5d !important;
}

select:focus {
  border: 1px solid #ddd !important;
}

input:focus,
textarea:focus {
  border: 0.5px solid #0cab5d !important;
}

body {
  font-family: 'Helvetica' !important;
  font-size: 1em;
  color: #344563;
  letter-spacing: 0.02em !important;
  background: #ffffff;
}

.what-we-do-icon {
  fill: #097c8c;
}

/* Navbar Starts Here */

.navbar {
  width: 100%;
  background: transparent;
  transition: 0.6s;
  padding-top: 20px;
  padding-left: 60px;
  padding-right: 60px;
  z-index: 1000;
}

.navbar.scrolled {
  padding-top: 9px;
  background-color: #fff !important;
  transition: background-color 200ms linear;
  box-shadow: 0px 2px 7px rgba(79, 79, 79, 0.03);
  transition: 0.7s;
}

.container-fluid {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.navbar img.brand-logo {
  height: 36.2px;
}

.navbar .navbar-nav {
  margin-top: 0px;
}

.navbar .navbar-nav li {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 9px;
  margin-bottom: 12px;
}

.navbar .navbar-nav li .links {
  font-size: 16px;
  line-height: 19px;
  color: #565555;
  font-weight: 700;
}

.navbar .navbar-nav li .links.active {
  color: #0cab5d;
}
.navbar .navbar-nav li .links:focus {
  color: #0cab5d;
}

.navbar .navbar-nav li .links:hover {
  color: #0cab5d;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}

.navbar .nav-item.dropdown .dropdown-menu {
  padding: 0;
  margin: 0;
  margin-top: 15px;
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 6px;
  width: 544px;
  transform: translate(-50%, 0);
}

.dropdown-solution {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  font-weight: bold;
  text-align: center;
  color: #000000;
}

.dropdown-solution-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: 0.03em;
  color: #000000;
}

.dropdown-solution-content {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
  color: #292b2a;
  width: 234px;
}

.dropdown-solution-button {
  width: 93px;
  height: 40px;
  background: #086d3c;
  border-radius: 3px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #ffffff;
  border: none;
  outline: none;
}

.navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  padding: 11px 20px;
  padding-right: 40px;
  font-size: 0.85em;
}
.navbar .nav-item.dropdown .dropdown-menu .dropdown-item.active {
  background: rgba(12, 171, 93, 0.2);
  color: #0cab5d;
  font-weight: bold;
}
.navbar .nav-item.dropdown .dropdown-menu .dropdown-item:focus {
  background: #fff !important;
  color: #000;
}

.open-nav {
  display: none;
}

/* Main Page Starts Here */

.main {
  margin: 0;
  padding: 0;
}

/* Smart Section for hero  */
section.hero {
  background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-img.png)
    no-repeat top right;
}
section.contact {
  background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-co-img.png)
    no-repeat top right;
}
section.careers {
  background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-ca-img.png)
    no-repeat top right;
}
section.transmonitor {
  background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-t-img.png)
    no-repeat top right;
}
section.velabank {
  background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-v-img.png)
    no-repeat top right;
}

/* Homepage Starts Here */

.section-one {
  width: 100%;
  margin-top: -73px;
}

.section-one .left {
  width: 100%;
  margin-top: 193px;
}

.section-one .left .head-text {
  font-size: 46px;
  letter-spacing: 0.5px;
  line-height: 60px;
  font-weight: bold;
  color: #1c201e;
  margin-bottom: 30px;
}

.section-one .left .sub-head-text {
  color: #292b2a;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: 59px;
}

.section-one .left .primary-btn {
  background: #0cab5d;
  box-shadow: 0px 15px 25px rgba(11, 163, 88, 0.15);
  border-radius: 6px;
  padding: 16px 17px;
  padding-top: 15px;
  font-size: 0.90em;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #fff;
  border: none;
  cursor: pointer;
}
.section-one .left .primary-btn:hover {
  text-decoration: none;
}

.section-one img {
  position: absolute;
  top: 0;
  right: 0;
  width: 496px;
  height: 699px;
}

.section-two {
  width: 100%;
  background-image: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/right-back-img.png);
  padding-top: 206px !important;
  padding-bottom: 100px !important;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left;
}

.section-two h3 {
  font-size: 1.4em;
  letter-spacing: 1.5px;
  font-weight: bolder;
  color: #072D22;
  text-align: center;
  text-transform: uppercase;
}

.section-hr {
  width: 156px;
  margin-top: -18px;
  border: 5px solid rgba(188, 232, 210, 0.2);
  margin-bottom: 70px;
}

.section-two .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
}

.section-two .cards .card {
  background: #ffffff;
  box-shadow: 0px 12px 40px rgba(57, 57, 57, 0.06);
  border-radius: 6px;
  text-align: center;
  border: none;
  padding: 20px;
  position: relative;
  /* width: 360px; */
  height: 418px;
}

.section-two .cards .card .what-we-do-card-icon {
  position: absolute;
  top: 0;
}

.section-two .cards .card .what-we-do-icon {
  margin: 0 auto;
  width: 50px;
  margin-bottom: 35px;
}

.section-two .cards .card h5 {
  font-weight: bolder;
  color: #097c8c;
  letter-spacing: 0.5px;
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  padding: 0px 65px;
}

.section-two .cards .card p {
  margin: 0;
  margin-top: 10px;
  color: #292b2a;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 23px;
  text-align: center;
  padding: 0 13px;
}

.section-three {
  position: relative;
  width: 100%;
  color: #fff;
  padding-top: 150px !important;
  padding-bottom: 180px !important;
  background: linear-gradient(150.33deg, #6fc299 11.02%, #1c965a 104.36%);
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
}

.section-three-1 {
  position: absolute;
  right: 0;
  top: 26px;
  width: 50%;
  color: #fff;
  padding-top: 300px;
  background: #0ed071;
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
  clip-path: polygon(0% 0, 100% 13%, 100% 96%, 0 100%);
  border-top-left-radius: 16px;
}

.relative {
  position: relative;
}

.section-three-2 {
  position: absolute;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 190px 0;
  background: rgba(22, 79, 51, 0.05);
  clip-path: polygon(0% 30%, 100% 0%, 100% 70%, 0% 100%);
}
.section-three-3 {
  position: absolute;
  left: 0;
  width: 100%;
  color: #fff;
  padding-top: 150px;
  padding-bottom: 180px;
  background: linear-gradient(
    180deg,
    rgba(18, 71, 45, 0.1) 0%,
    rgba(255, 255, 255, 0) 65.94%,
    rgba(255, 255, 255, 0) 102.19%
  );
  clip-path: polygon(0% 0%, 100% 40%, 100% 100%, 0% 70%);
}

.section-three h3 {
  font-size: 1.7em;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 80px;
  text-transform: uppercase;
  font-weight: bold;
}

.section-three .value-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 100px;
}

.section-three .value-cards .value-card {
  display: flex;
}

.section-three .value-cards .value-card .value-card-img {
  height: 70px;
  margin-right: 30px;
}

.section-three .value-cards .value-card .info h6 {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 20px;
  letter-spacing: 0.04em;
}

.section-three .value-cards .value-card .info p {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #cefae4;
}

.section-four {
  width: 100%;
  background-image: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/left-back-img.png);
  background-repeat: no-repeat;
  background-size: 200px 393px;
  background-position: right;
  margin-top: -70px;
  position: relative;
  z-index: -100;
  height: 550px;
}

.section-four .in-good-hand {
  width: 100%;
  background-image: url(https://vela-web-site.s3.us-east-2.amazonaws.com/svg/in-good-hands.svg);
  background-position: left;
  background-repeat: no-repeat;
  height: 550px;
}

.section-four .in-good-company {
  width: 70%;
  text-align: left;
  margin-left: 20px;
}

.image-section-one {
  align-items: center;
}

.image-section-two {
  align-items: center;
  margin-top: 40px;
}

.image-one img {
  width: 170px;
  height: 40px;
}

.image-two img {
  margin-left: 30px;
  width: auto;
  height: 80px;
}

.image-three img {
  width: 90px;
  height: 80px;
}

.image-four img {
  margin-left: 30px;
  width: 200px;
  height: 30px;
} 

.section-four p {
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #2c4639;
  margin-bottom: 70px;
}

.section-four .images-div {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
}

.section-four .images-div img {
  margin: 0 auto;
  width: 100px;
  height: 89px;
  border-radius: 6px;
  object-fit: cover;
}

/* Careers Page Starts Here */

.section-two.positions {
  padding-top: 150px !important;
  padding-bottom: 170px !important;
}

.section-two.positions h4 {
  font-size: 1.3em;
  font-weight: bold;
  color: #1c201e;
  text-align: left;
  margin-bottom: -8px;
}

.section-two.positions hr {
  border: none;
  background: rgba(188, 232, 210, 0.2);
  border-radius: 1px;
  height: 10px;
  width: 150px;
  margin: 0;
  margin-bottom: 70px;
}

.section-two.positions .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  width: 80%;
}

.section-two.positions .cards .position-card {
  background: #ffffff;
  box-shadow: 0px 12px 40px rgba(57, 57, 57, 0.06);
  border-radius: 6px;
  text-align: left;
  padding: 30px 30px;
  border: none;
}

.section-two.positions .cards .position-card .card-icon {
  width: 45px;
  height: 45px;
  margin: 0px;
  margin-bottom: 30px;
}

.section-two.positions .cards .position-card .info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.section-two.positions .cards .position-card .info .left {
  margin-right: 10px;
}

.section-two.positions .cards .position-card .info .left h5 {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.2em;
}

.section-two.positions .cards .position-card .info .left p {
  margin: 0;
  font-size: 0.9em;
}

.section-two.positions .cards .position-card .info .arrow {
  width: 40px;
  height: 40px;
  margin: 0;
}

/* Careers Page Ends Here */

/* Contact Page Starts Here */

.section-two.contact {
  padding-top: 150px !important;
  padding-bottom: 100px !important;
}

.section-two.contact h4 {
  font-size: 1.3em;
  font-weight: bold;
  color: #1c201e;
  text-align: left;
  margin-bottom: -8px;
}

.section-two.contact hr {
  border: none;
  background: rgba(188, 232, 210, 0.2);
  border-radius: 1px;
  height: 10px;
  width: 180px;
  margin: 0;
  margin-bottom: 40px;
}

.section-two.contact .form-div {
  display: flex;
  justify-content: space-between;
}

.section-two.contact .form-div .form {
  width: 40%;
}

.section-two.contact .form-div .form .form-group {
  margin-bottom: 25px;
}

.section-two.contact .form-div .form .form-group label {
  margin-bottom: 6px;
  color: #292b2a;
  font-size: 0.89em;
  font-weight: bold;
}

.section-two.contact .form-div .form .form-group input,
.section-two.contact .form-div .form .form-group textarea {
  border: 1px solid #eaf2f1;
  border-radius: 3px;
  font-family: 'Lato', sans-serif;
  font-size: 0.96em;
  letter-spacing: 0.03em;
}

.section-two.contact .form-div .form .sign-up-btn {
  background: #0cab5d;
  box-shadow: 0px 15px 25px rgba(11, 163, 88, 0.15);
  border-radius: 6px;
  color: #fff;
  font-size: 0.9em;
  letter-spacing: 0.03em;
  padding: 12px;
  margin-top: 20px;
  width: 100%;
}

.section-two.contact .form-div .infos {
  margin: 0 auto;
  width: 40%;
  margin-top: 100px;
}

.section-two.contact .form-div .infos .info {
  width: 100%;
  padding: 30px 36px;
  background: #ffffff;
  box-shadow: 0px 12px 40px rgba(57, 57, 57, 0.06);
  border-radius: 6px;
  margin-bottom: 69px;
}

.section-two.contact .form-div .infos .info img {
  width: 55px;
  height: 55px;
  margin-bottom: 24px;
}

.section-two.contact .form-div .infos .info p {
  margin: 0px;
  font-weight: bold;
}

#map {
  width: 100%;
  height: 410px;
  margin-bottom: 54px;
}

/* Contact Page Ends Here */

/* Solutions Page Starts Here */

.section-one img {
  z-index: -1;
}

.macbook-img {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  background-image: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/right-back-img.png);
  padding-top: 100px !important;
  padding-bottom: 100px !important;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left;
}

.macbook-img img {
  margin: 0 auto;
  width: 50%;
  z-index: 1;
}

.section-two.works {
  text-align: center;
  width: 100%;
  padding-top: 0px !important;
  padding-bottom: 102px !important;
  background-image: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/left-back-img.png);
  background-repeat: no-repeat;
  background-size: 150px 300px;
  background-position: right;
}

.section-two.works h3 {
  font-size: 1.7em;
  font-weight: bold;
  margin-bottom: 32px;
}

.section-two.works p {
  font-size: 1em;
  line-height: 28px;
  color: #292b2a;
  margin: 0 auto;
  width: 60%;
}

.next-div {
  padding-top: 100px !important;
  padding-bottom: 110px !important;
  text-align: center;
}

.next-div a {
  background: rgba(12, 171, 93, 0.1);
  border-radius: 6px;
  color: #0cab5d;
  font-weight: bold;
  font-size: 0.9em;
  padding: 13px 13px;
  padding-bottom: 13px;
  padding-right: 13px;
}
.next-div a:hover {
  text-decoration: none;
}

.next-div a img {
  margin-top: -2px;
  margin-left: 15px;
}
.next-div a img.rotate {
  margin-top: -2px;
  margin-left: 0px;
  margin-right: 15px;
  transform: rotate(180deg);
}

/* Solutions Page Ends Here */

/* Footer Starts Here */

footer.main {
  margin: 0;
  margin-top: 0px;
  padding-top: 65px;
  padding-bottom: 32px;
  background: #f0f0f0;
}


footer .columns-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0px;
  padding: 0px 180px 0px 180px;
}

footer .footer-content .footer-logo {
  width: 83px;
  height: 39px;
}

footer .set .footer-link-header-text {
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
  color: #292b2a;
}

footer .set .footer-links p {
  margin-bottom: 7px;
  color: #5e6c84;
  font-size: 0.9em;
}



footer .set .footer-links .link {
  color: #5e6c84;
  letter-spacing: 0.02em;
}
footer .set .footer-links .link:hover {
  color: #344563;
  text-decoration: none;
}

footer hr {
  margin: 0 auto;
  margin-top: 44px;
  margin-bottom: 24px;
  width: 80%;
}

footer .copy {
  font-size: 0.9em;
  text-align: center;
  margin: 0px;
}

/* Footer Ends Here */

/*Responsive Page Starts Here*/

@media screen and (max-width: 3000px) {
  .section {
    padding: 0px 350px;
  }
}

@media screen and (max-width: 1900px) {
  .section {
    padding: 0px 250px;
  }
}

@media screen and (max-width: 1650px) {
  .section {
    padding: 0px 200px;
  }
}

@media screen and (max-width: 1550px) {
  .section {
    padding: 0px 150px;
  }
}

@media screen and (max-width: 1450px) {
  .navbar {
    padding-left: 80px;
    padding-right: 80px;
  }

  .section {
    padding: 0px 80px;
  }
}

@media screen and (max-width: 1350px) {
  .navbar {
    padding-left: 50px;
    padding-right: 50px;
  }

  .section {
    padding: 0px 50px;
  }

  footer .columns-row {
    padding: 0px 130px 0px 130px;
  }
}

@media screen and (max-width: 1050px) {
  .navbar {
    padding-left: 40px;
    padding-right: 40px;
  }

  .section {
    padding: 0px 40px;
  }

  .section-two .cards .card h5 {
    padding: 0px;
  }

  footer {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    padding-left: 30px;
    padding-right: 30px;
  }

  /* .navbar.scrolled {
        padding-top: 10px;
        padding-bottom: 8px;
    } */

  .navbar .navbar-nav {
    margin-top: 15px;
    margin-left: 0px;
  }

  .navbar .nav-item.dropdown .dropdown-menu {
    padding: 0;
    margin: 0;
    margin-top: 15px;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.06);
    border: none;
    border-radius: 6px;
    width: 544px;
    transform: translate(-50%, 0);
    transform: translate(0%, 0);
    width: 555px;
  }

  .navbar .navbar-nav li {
    margin-right: 0px;
    margin-left: 5px;
  }

  .open-nav {
    display: block;
    position: relative;
    width: 30px;
    margin-left: 10px;
    margin-top: 0px;
    transform: rotate(180deg);
    opacity: 0.5;
  }

  .section {
    padding: 0px 30px;
  }

  footer {
    padding-left: 30px;
    padding-right: 30px;
  }

  footer .columns-row {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 0px;
    padding: 0px 180px 0px 180px;
  }

  .footer-content .set {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 970px) {
  .section-one {
    grid-template-columns: 2fr 3fr;
    grid-gap: 50px;
  }

  .section-one img {
    width: 396px;
    height: 599px;
  }

  .section-three {
    clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 96%);
  }

  .section-three .value-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .macbook-img img {
    margin: 0 auto;
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .section-two .cards {
    grid-gap: 20px;
  }

  .section-two.positions .cards {
    width: 100%;
  }

  footer .columns-row {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 0px;
    padding: 0px 100px 0px 100px;
  }

  
}

@media screen and (max-width: 830px) {
  footer .columns-row {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 0px;
    padding: 0px 80px 0px 80px;
  }

}

@media screen and (max-width: 800px) {
  section.hero {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-img.png)
      no-repeat top right;
    background-size: 450px 730px;
  }
  section.contact {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-co-img.png)
      no-repeat top right;
    background-size: 450px 730px;
  }
  section.careers {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-ca-img.png)
      no-repeat top right;
    background-size: 450px 730px;
  }
  section.transmonitor {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-t-img.png)
      no-repeat top right;
    background-size: 450px 730px;
  }
  section.velabank {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/banner-v-img.png)
      no-repeat top right;
    background-size: 450px 730px;
  }

  .section-one {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }

  .section-one .left {
    margin-top: 173px;
  }

  .section-one .left .primary-btn {
    padding: 20px 22px;
    padding-top: 19px;
    font-size: 0.9em;
  }

  .section-one img {
    position: relative;
    width: 100%;
    height: 799px;
    margin-bottom: -110px;
  }

  .section-two {
    background-position: inherit;
  }

  .section-two .cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

  .section-three .value-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 70px;
  }

  .section-two.contact .form-div {
    display: block;
  }

  .section-two.contact .form-div .form {
    width: 70%;
  }

  .section-two.contact .form-div .infos {
    margin: 0;
    width: 70%;
    margin-top: 100px;
  }

  .section-two.contact .form-div .infos .info {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-one .left .head-text {
    font-size: 2.4em;
    line-height: 50px;
    margin-bottom: 50px;
  }

  .section-one .left .sub-head-text {
    font-size: 1.1em;
    line-height: 25px;
  }
  
  .section {
    padding: 0px 20px;
  }

  .section-four {
    background: none;
  }

  footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  footer .set .footer-logo {
    display: block;
    margin-left: -10px;
  }

  footer .set .footer-links .link {
    color: #0a0a0a;
    letter-spacing: 0.02em;
  }

  footer .set .footer-links p {
    margin-bottom: 7px;
    color: #0a0a0a;
  }

  footer .set .footer-link-header-text {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 657px) {
  footer .columns-row {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 0px;
    padding: 0px 30px 0px 30px;
  }
}

@media screen and (max-width: 630px) {
  .section-three .value-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 70px;
    margin: 0 auto;
    width: 85%;
  }

  .section-three .value-cards .value-card {
    display: block;
    text-align: center;
  }

  .section-three .value-cards .value-card .value-card-img {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .section-three .value-cards .value-card img {
    height: 60px;
    margin-right: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 24px;
  }

  .section-four {
    margin-left: 0px;
  }

  .section-four .images-div {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 40px;
  }

  .macbook-img img {
    margin: 0 auto;
    width: 100%;
  }

  .section-two.works p {
    width: 95%;
  }
  .section-four .in-good-hand {
    display: none;
  }

  .section-four .in-good-company {
    width: auto;
    margin: auto;
  }

  .section-four .in-good-company > div {
    margin-left: auto;
    margin-right: auto;
  }

  .section-two.positions .cards .position-card {
    text-align: center;
    align-items: center;
  }

  .section-two.positions .cards .position-card .info {
    width: 100%;
    display: block;
  }

  .section-two.positions .cards .position-card .info .left {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

@media screen and (ma-width: 614px) {
  footer .columns-row {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 0px;
    padding: 0px 0px 0px 0px;
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 70px;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .section {
    padding: 0px 15px;
  }

  .section-two.positions .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .section-two.contact .form-div .form {
    width: 100%;
  }

  .section-two.contact .form-div .infos {
    margin: 0;
    width: 100%;
    margin-top: 100px;
  }

  .next-div a {
    padding-right: 15px;
    padding-left: 15px;
  }

  footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 575px) {
  section.hero {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/header-bg.png)
      no-repeat top center;
    background-size: 100% 1100px;
    margin-top: -162px;
  }
  section.contact {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/res-banner-co-img.png)
      no-repeat top center;
    background-size: 100% 1100px;
    margin-top: -162px;
  }
  section.careers {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/res-banner-ca-img.png)
      no-repeat top center;
    background-size: 100% 1100px;
    margin-top: -162px;
  }
  section.transmonitor {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/res-banner-t-img.png)
      no-repeat top center;
    background-size: 100% 1100px;
    margin-top: -162px;
  }
  section.velabank {
    background: url(https://vela-web-site.s3.us-east-2.amazonaws.com/img/res-banner-v-img.png)
      no-repeat top center;
    background-size: 100% 1100px;
    margin-top: -162px;
  }

  .section-one {
    margin-bottom: 220px;
  }



  .section-one .left {
    margin-top: 75%;
    text-align: center;
  }

  .section-one .left .head-text {
    color: #fff;
    margin-bottom: 100px;
  }

  .section-one .left .sub-head-text {
    color: #fff;
    font-size: 1.1em;
  }

  .section-two {
    padding-top: 150px !important;
  }

  .section-two.contact {
    margin-top: 40px;
  }

  .macbook-img {
    margin-top: 400px;
    width: 100%;
  }

  .navbar .nav-item.dropdown .dropdown-menu {
    width: 100%;
  }

  .navbar__navbar-solution-img {
    display: none;
  }

  .dropdown-solution-button {
    display: none;
  }

  
}

@media screen and (max-width: 540px) {
  .navbar .mr-auto {
    width: 90%;
  }

  .section-two.positions .cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  footer .columns-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0px;
  }
}

@media screen and (max-width: 530px) {
  section.hero,
  section.contact,
  section.careers,
  section.transmonitor,
  section.velabank {
    background-size: 100% 950px;
    margin-top: -150px;
  }

  .section-one {
    margin-bottom: 120px;
  }

  .section-one .left {
    margin-top: 75%;
  }

  .macbook-img {
    margin-top: 300px;
  }
}

@media screen and (max-width: 450px) {
  section.hero,
  section.contact,
  section.careers,
  section.transmonitor,
  section.velabank {
    background-size: 100% 780px;
    margin-top: -150px;
  }

  .section-one {
    margin-bottom: 50px;
  }

  .section-one .left {
    margin-top: 75%;
  }

  .header__header-left {
    margin-top: 56% !important;
  }

  .section-one .left .head-text {
    color: #fff;
    font-size: 40px;
    padding-top: 40px;
    line-height: 1.1em;
    margin-bottom: 50px;
  }

  .macbook-img {
    margin-top: 220px;
  }
}

@media screen and (max-width: 420px) {
  footer .columns-row {
    grid-template-columns: repeat(1, 1fr);
  }
  .section-four{
    height: 650px;
  }

  .section-four .in-good-hand {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 255),
        rgba(255, 255, 255, 0.4)
      ),
      url(https://vela-web-site.s3.us-east-2.amazonaws.com/svg/in-good-hands.svg);
  }

  .section-four .in-good-company {
    position: absolute;
    right: 5px;
    left: 5px;
    top: 120px;
  }

  .section-four .in-good-company p {
    text-align: center;
    font-weight: bold;
    margin-bottom: 48px;
  }

  .section-four .in-good-company > div {
    flex-direction: column;
  }

  .image-section-one .image-one {
    margin-bottom: 40px;
  }

  .image-section-two .image-three {
    order: 2;
    margin-bottom: 40px;
  }

  .image-section-two .image-four {
    order: 1;
    margin-bottom: 40px;
  }

  .in-good-company img {
    margin-left: 0;
  }

  .section-three-1 {
    top: 32px;
  }
}

@media screen and (max-width: 400px) {
  .section-one .left .head-text {
    font-size: 2.4em;
  }

  .section-three .value-cards {
    width: 100%;
  }

  footer {
    padding: 25px 10px;
  }
}

@media screen and (max-width: 380px) {
  section.hero,
  section.contact,
  section.careers,
  section.transmonitor,
  section.velabank {
    background-size: 100% 680px;
    margin-top: -150px;
  }

  .header__header-left {
    margin-top: 32% !important;
  }

  .section-one {
    margin-bottom: 0px;
  }

  .section-one .left .head-text {
    font-size: 1.8em;
    line-height: 1.1em;
    margin-bottom: 35px;
    padding-top: 110px;
  }

  .macbook-img {
    margin-top: 150px;
  }
}

@media screen and (max-width: 340px) {
  section.hero,
  section.contact,
  section.careers,
  section.transmonitor,
  section.velabank {
    background-size: 100% 680px;
    margin-top: -130px;
  }

  .section-one {
    margin-bottom: 0px;
  }

  .macbook-img {
    margin-top: 180px;
  }

  .section-two.positions {
    padding-top: 150px !important;
    padding-bottom: 100px !important;
  }
}
